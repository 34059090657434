import React, { useEffect, useState } from 'react';
import './styles.css';
import LogAnalysis from '../../container/User-panel/AnalysisPopup/LogAnalysis';
import CompleteAnalysis from '../../container/User-panel/AnalysisPopup/CompleteAnalysis';
import AppServices from '../../../services/appServices';
import { APIEndpoint } from '../../../config/apiendpoint';
import { convertDate } from '../../utils/dateHelper';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

function ThirtyDayAnalysis({ patientData, staffList, updateLog }) {
  const _appServices = new AppServices();
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [showCompleteAnalysis, setShowCompleteAnalysis] = useState(false);
  const [logDatas, setLogDatas] = useState(null);
  const [logAnalysisData, setLogAnalysisData] = useState()
  const [btnActive, setIsBtnActive] = useState(false);

  const handlePendingClick = (entry) => {
    setPopupContent(`Details for entry with Cycle End Date: ${entry.cycleEndDate}`);
    setShowPopup(true);
    setLogAnalysisData(entry)
  };

  const handleCompleteAnalysis = (entry) => {
    setShowCompleteAnalysis(true)
    setLogAnalysisData(entry)
  }

  const handleClose = () => {
    setShowPopup(false);
  };
  const updateAnalysis = () => {
    getThirtyDayAnalysis()
    updateLog()
  }
  useEffect(() => {
    getThirtyDayAnalysis()
  }, [])

  const fetchTestData = async () => {
    try {
      if (logDatas?.length > 0) {
        return
      }
      const resp = await _appServices.postData(`${APIEndpoint.fetchTestData}/${patientData?.uuid}`);
      window.location.reload();

      // if (resp.status === 201 ) {
      //   console.log("dubbby data",resp)
      //   Swal.fire({
      //         icon: "success",
      //         title: "Success",
      //         text: resp?.message ,
      //       }).then(()=>{
      //         window.location.reload();
      //       })

      // }
    } catch (error) {
      console.error("Error fetching datad:", error);
    }
  }

  const getThirtyDayAnalysis = async () => {
    try {
      const resp = await _appServices.getData(`${APIEndpoint.logAnalysis}?patientUid=${patientData?.uuid}`);
      if (resp?.status === 200 && resp?.success == true) {
        let data = resp.data
        let addLogDatas = []
        if (data && data.logDatas?.length) {
          data.logDatas.map((item) => {
            addLogDatas.push({
              cycleEndDate: convertDate(item.cycleEndDate),
              analysis: item.analysis,
              staff: item.status ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}` : '',
              dateComplete: item.status ? convertDate(item.completedAt) : 'PENDING',
              id: item.uuid,
              item: item,
              analysisNotes: item.analysisNotes,
              staffUid: item.status ? `${item?.createdBy?.uuid}` : ''
            })
          })
          setLogDatas(addLogDatas)
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return (
    <div className="graph-container">

      <div className='d-flex justify-content-between'> <h2 className='graphHeading ml-5 mb-4'>30-Day Analysis</h2></div>
      <div className="bordertable-container ml-5">
        <table className="borderdata-table">
          <thead>
            <tr>
              <th>Cycle End Date</th>
              <th>Analysis</th>
              <th>Staff Name</th>
              <th>Date Complete</th>
            </tr>
          </thead>
          <tbody>
            {logDatas?.length ? logDatas.map((entry, index) => (
              <tr key={index}>
                <td>{entry.cycleEndDate}</td>
                <td>{entry.analysis}</td>
                <td>{entry.staff}</td>
                <td style={{ color: '#03A84E' }}>
                  {entry.dateComplete.toLowerCase() === 'pending' ? (
                    <span className="pending" onClick={() => handlePendingClick(entry)}>
                      {entry.dateComplete}
                    </span>
                  ) : (
                    // entry.dateComplete
                    <span style={{ cursor: 'pointer' }} onClick={() => handleCompleteAnalysis(entry)}>{entry.dateComplete}</span>
                  )}
                </td>
              </tr>
            )) : null}
          </tbody>
        </table>
        {logDatas == null && (
          <div className="d-flex align-items-center justify-content-center pt-2">
            <p className="graphText thin-heading">No Data found</p>
          </div>
        )}
      </div>

      {/* {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Popup Title</h3>
            <p>{popupContent}</p>
            <button onClick={handleClose}>Close</button>
          </div>
        </div>
      )} */}
      <LogAnalysis show={showPopup} patientData={patientData} onHide={handleClose} analysisData={logAnalysisData} staffList={staffList} onUpdate={updateAnalysis} />
      <CompleteAnalysis show={showCompleteAnalysis} patientData={patientData} onHide={() => { setShowCompleteAnalysis(false) }} analysisData={logAnalysisData} staffList={staffList} />
    </div>
  );
}

export default ThirtyDayAnalysis;
