type EnvConfig = {
  baseURL: string;
  clientId: string;
  idMeUrl: string;
  idMeCallBackUrl: string;
  redirectedUri: string;
  chatWidgetUrl: string;
  privacyUrl: string;
  termsConditions: string;
  faq: string;
  publicKey: string;
  captchaKey: string;
  zohoWidgetCode: string;
};

type EnvOptions = {
  dev: EnvConfig;
  uat: EnvConfig;
  prod: EnvConfig;
  default: EnvConfig;
};

const selectEnv = (env: keyof EnvOptions): EnvConfig => {
  const envOptions: EnvOptions = {
    dev: {
      baseURL: 'https://otis-health-rpm-dev.uw.r.appspot.com/v1/',
      clientId: '90008cb169a2f2cca6a6154014a4ad3f',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'https://dev.rpm.otishealth.net/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000617009?orgId=838132203",
      privacyUrl: "https://otishealth.net/rpm_privacy",
      termsConditions: "https://otishealth.net/rpm_terms-of-use",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQKsghPh9Ct9cNzUMVXITkWG9aKIVKgXMh6AV1zH1yY+SSuSJbydTJKvCDtHtZt4gExeS7x+H38+FtJP6YpTtKq8Wpb5WZVGcIgBUJc7k547N6MVjoctK5T59OcJ+2G5Mm5Uc9YSmymentIxDcDhcBEN16JgFC3APbvtwXTz0xPwIDAQAB",
      captchaKey: '6LdTNTAqAAAAACX-fLhbsvkhfNPmCTCzc_TqqRlj',
      zohoWidgetCode: 'siq2840837a3d127e06edece6de35feff4fe59203ab8149c0c4daae77aa2b3b9b9d'
    },
    uat: {
      baseURL: 'https://rpmuat-dot-otis-health-rpm-uat.uw.r.appspot.com/v1/',
      clientId: '90008cb169a2f2cca6a6154014a4ad3f',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'https://uat.rpm.otishealth.net/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000617009?orgId=838132203",
      privacyUrl: "https://otishealth.net/rpm_privacy",
      termsConditions: "https://otishealth.net/rpm_terms-of-use",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHyWOAKxpgpPO4n+FKT4b87mzOrmR+JJ9DoTzUrDfpUS7lIXAmSuqDljVVaWQ3+jzuyOCwGKmgauD8OE1TG90WXjZx5SQKiZV5evoF3Xfs8KuFFaM+RmYVd05B5wsJlPcew0qQgYYVhYlwKS/wDB8c5AsyUxxrZJNcsEe+2tME8jAgMBAAE=",
      captchaKey: '6LeX2jAqAAAAAIuZ8NMt2MR8N8ARLt7idIZPWVo_',
      zohoWidgetCode: 'siq2840837a3d127e06edece6de35feff4fe59203ab8149c0c4daae77aa2b3b9b9d'
    },
    prod: {
      baseURL: 'https://rpmprod-dot-otis-health-rpm-prod.uw.r.appspot.com/v1/',
      clientId: '6137c084d4a80a89761852caf231a8a1',
      idMeUrl: 'https://api.id.me/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'https://rpm.otishealth.net/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000701001?orgId=838132203",
      privacyUrl: "https://otishealth.net/rpm_privacy",
      termsConditions: "https://otishealth.net/rpm_terms-of-use",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAI+s9t5JVUutqqwV3gbXPv+W4Xs/GBW8DYh03I6ZZLOI1vLsAR2Bs3GzYzAmmjmkVIGDIodvjdktNiHQpPKoerkCAwEAAQ==",
      captchaKey: '6LfzrTEqAAAAAGFw_QdqHMe7WHzkPxuQcpbyXqP_',
      zohoWidgetCode: 'siq97cd6a7f22b7019ecedd78956f6f7ddd536dbb34e47d20193b123bc67fc05ab2'
    },
    default: {
      baseURL: 'http://localhost:8000/v1/',
      clientId: '90008cb169a2f2cca6a6154014a4ad3f',
      idMeUrl: 'https://api.idmelabs.com/oauth/authorize?client_id=',
      idMeCallBackUrl: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2/erx/registration',
      redirectedUri: 'http://localhost:3000/idmehandler',
      chatWidgetUrl: "https://desk.zoho.com/portal/api/web/inapp/931467000000617009?orgId=838132203",
      privacyUrl: "https://otishealth.net/privacy/",
      termsConditions: "https://otishealth.net/terms-of-use/",
      faq: "https://otishealth.net/faqs/",
      publicKey: "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQKsghPh9Ct9cNzUMVXITkWG9aKIVKgXMh6AV1zH1yY+SSuSJbydTJKvCDtHtZt4gExeS7x+H38+FtJP6YpTtKq8Wpb5WZVGcIgBUJc7k547N6MVjoctK5T59OcJ+2G5Mm5Uc9YSmymentIxDcDhcBEN16JgFC3APbvtwXTz0xPwIDAQAB",
      captchaKey: '6LdTNTAqAAAAACX-fLhbsvkhfNPmCTCzc_TqqRlj',
      zohoWidgetCode: 'siq2840837a3d127e06edece6de35feff4fe59203ab8149c0c4daae77aa2b3b9b9d'
    }
  };

  return envOptions[env] || envOptions.default;
};

const envConfig: EnvConfig = selectEnv('prod');

export { envConfig };

